<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div  class="content-form">
            <v-tabs-items :touchless="true" v-model="tabWarehouse">
              <v-tab-item>
                <edit-warehouse-tab-content-1-layout
                  :texts="texts"
                  :arr="arr"
                />
              </v-tab-item>
              <v-tab-item>
                <edit-warehouse-tab-content-2-layout
                  :texts="texts"
                  :arr="arr"
                />
              </v-tab-item>
              <!-- <v-tab-item>
                <edit-warehouse-tab-content-3-layout
                  :texts="texts"
                  :arr="arr"
                />
              </v-tab-item> -->
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
    arr: {
      type: Object,
      default: {},
    },
  },
  name: "EditWarehouseContentLayout",
  data() {
    return {
      //VARIABLES
    };
  },
  computed: {
    tabWarehouse: function () {
      return this.$store.state.tabWarehouse;
    },
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: #f2f2f4;
}
</style>